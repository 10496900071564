import React, { Component, Suspense, useEffect } from "react";
import {
  HashRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./scss/style.scss";
import { CSpinner } from "@coreui/react";
import "./app.css";
import { useCookies } from "react-cookie";
const Loading = (
  <div
    style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      justifyItems: "center",
      alignItems: "center",
    }}
  >
    <CSpinner color="primary" />
  </div>
);

const LoadingComponent = () => {
  return Loading;
};

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies();

  useEffect(() => {
    const loginNotRequired = ["/login", "/register", "/form"]; // Add paths where login is not required
    const requiresLogin = !loginNotRequired.includes(location.pathname);
    const isLoggedIn = localStorage.getItem("token") ? true : false;
    const isUrl = localStorage.getItem("before_url");
    if (requiresLogin && !isLoggedIn) {
      if(location.search != '') {
        localStorage.setItem("before_url", location.pathname + location.search);
      }else {
        localStorage.setItem("before_url", location.pathname);
      }
      navigate("/login");
    }
    if (
      (location.pathname === "/login" || location.pathname === "register") &&
      isLoggedIn
    ) {
      if(isUrl){
        navigate(isUrl);
        localStorage.removeItem("before_url");
      }else navigate("/redemption", { replace: true });
    }
  }, [location]);
  return (
    <Suspense fallback={Loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route
          exact
          path="/register"
          name="Register Page"
          element={<Register />}
        />
        <Route
          path="/loading"
          name="Loading"
          element={<LoadingComponent></LoadingComponent>}
        ></Route>
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  );
}

export default App;
